import React from "react"
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Header";
import styled, { css } from "styled-components";
import ReactGA from "react-ga";

const analyticsLinkClick = (link) => {
  ReactGA.event({
    category: 'Category',
    action: `Clicked category link: ${link}`
  })
}

const IndexPage = ({ data }) => {
  return <Layout>
    <SEO title="Home" />
    <Header />
    <Container>
      <Hero>A collection of free open source resources <br />for all your design needs</Hero>
      <WrapperCategory>
      { data.allCategory.nodes.map((category, index) => {
          const { name, childrenFile } = category;
          const { src } = childrenFile[0].childImageSharp.fixed;
          const slug = name.split(" ").join("_");

          return <Link to={ `/${ slug }` } key={ index } onClick={ () => analyticsLinkClick(slug) }>
            <Category key={ index }>
              {<Icon src={ src } />}{ name }
            </Category>
          </Link> 
      }) }
      </WrapperCategory>
    </Container>
  </Layout>
};

const Hero = styled.p`
  font-size: 36px;
  text-align: center;
  margin-bottom: 78px;

  @media (max-width: 680px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 17px;
    padding: 0 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Container = styled.div`
  min-height: calc(100vh - 97px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WrapperCategory = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 750px;
  margin: 0 auto 36px auto;
`;

const Category = styled.div`
  background: #dadada;
  padding: 12px;
  text-transform: uppercase;
  color: #171717;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 180px;
  font-weight: bold;
  font-size: 10px;
  border-radius: 5px;
  margin: 0 20px;
  margin-bottom: 20px;
  transition: 0.8s all cubic-bezier(0.2, 0.8, 0.2, 1);

  &:hover {
    transform: translateY(-1px);
  }
`;

const Icon = styled.span`
  width: 15px;
  height: 15px;
  background: #555;
  display: inline-block;
  margin-right: 10px;

  ${ props => css`background: url(${ props.src }); background-size: contain; background-repeat: no-repeat;` }
`;

export default IndexPage

export const query = graphql`
query {
  allCategory {
    nodes {
      image
      name
      childrenFile {
        childImageSharp {
          fixed(height: 15, width: 15) {
            src
          }
        }
      }
    }
  }
}
`